import React from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content from '../components/Content'
import Text from '../components/Text'
import Intro from '../components/Intro'
// import News from '../components/News'
import Map from '../components/Map'
import Title from '../components/Title'
import Lawyers from '../components/Lawyers'
import Spacer from '../components/Spacer'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const HomePage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout footer={{ plain: true }}>
      <SEO title={intl.formatMessage({ id: 'seo.title' })} />
      <Intro
        image={data.introImage1}
        images={[
          data.introImage1,
          data.introImage3,
          data.introImage4,
          data.introImage5,
          data.introImage6,
        ]}
        full
      />
      <Content>
        <Title align="center">
          {intl.formatMessage({ id: 'home.heading' })}
        </Title>
        <Fade bottom>
          <Text align="center" size="intro">
            {intl.formatMessage({ id: 'home.intro' })}
          </Text>
        </Fade>
      </Content>
      <Spacer />
      <Lawyers />
      {/*<News />*/}
      <Map />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  fragment IntroImage on File {
    childImageSharp {
      fluid(maxWidth: 1920, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  query {
    introImage1: file(relativePath: { eq: "1.jpg" }) {
      ...IntroImage
    }
    introImage2: file(relativePath: { eq: "2.jpg" }) {
      ...IntroImage
    }
    introImage3: file(relativePath: { eq: "3.jpg" }) {
      ...IntroImage
    }
    introImage4: file(relativePath: { eq: "4.jpg" }) {
      ...IntroImage
    }
    introImage5: file(relativePath: { eq: "5.jpg" }) {
      ...IntroImage
    }
    introImage6: file(relativePath: { eq: "6.jpg" }) {
      ...IntroImage
    }
  }
`
