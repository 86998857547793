import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import Content from './Content'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Wrapper = styled.section``

const Items = styled.div`
  display: flex;
  margin-top: 36px;
  margin-bottom: 22px;
  margin-left: -76px;

  @media (max-width: 768px) {
    display: block;
    text-align: center;
    margin-bottom: 2.5rem;
  }
`

// TODO: Combine layout with ./News.js (used on front page)
const Item = styled.div`
  color: ${props => props.theme.colors.primary};
  line-height: 1.5;
  margin-bottom: 30px;

  flex: 1;
  padding-left: 76px;

  > * {
    max-width: 482px;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &:last-of-type > * {
    margin-left: auto;
  }
`

const Name = styled.div`
  font-weight: 500;
`

const Description = styled.div``

const StyledImg = styled(Img)`
  min-height: 190px;
  max-height: 690px;
`

const Lawyers = () => {
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "front_anwaelte.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const lawyersTo = intl.locale === 'de' ? '/de/anwaelte' : '/en/lawyers'

  return (
    <Wrapper>
      <StyledImg fluid={data.placeholderImage.childImageSharp.fluid} />
      <Content>
        <Items>
          <Item>
            <Fade bottom>
              <Name>
                <Link to={lawyersTo}>Roman Jenal, MLaw</Link>
              </Name>
              <Description>
                {intl.formatMessage({ id: 'lawyer.male' })}
              </Description>
            </Fade>
          </Item>
          <Item>
            <Fade bottom>
              <Name>
                <Link to={lawyersTo}>Dr. iur. Alexandra Oberhuber-Wilhelm</Link>
              </Name>
              <Description>
                {intl.formatMessage({ id: 'lawyer.female' })}
              </Description>
            </Fade>
          </Item>
        </Items>
      </Content>
    </Wrapper>
  )
}

export default Lawyers
